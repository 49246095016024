import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  OAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import axios from "axios";

export const LoginProvider = {
  Google: 0,
  Facebook: 1,
  Apple: 2,
  EmailPassword: 3,
};

const handleUser = async (userCredential, provider, userEmail, password) => {
  const csrfRes = await axios.get("/v1/csrf/", {
    withCredentials: true,
  });
  const csrfToken = csrfRes.data.csrfToken;

  const user = userCredential.user;
  const idToken = await user.getIdToken();

  await axios.post(
    "/v2/session_login/",
    { idToken },
    {
      headers: {
        "X-CSRFToken": csrfToken,
      },
      withCredentials: true,
    }
  );

  const additionalUserInfo = getAdditionalUserInfo(userCredential);
  if (additionalUserInfo.isNewUser) {
    const email = userEmail ?? user.email;

    axios.post("/v2/register/", {
      email,
      password,
      firebase_uid: user.uid,
    });

    if (provider === LoginProvider.EmailPassword) {
      sendEmailVerification(user);
    }
  }
};

const handleError = (error) => {
  console.error(error);
  throw error;
};

export const registerWithEmailPassword = async (email, password) => {
  try {
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    await handleUser(
      userCredential,
      LoginProvider.EmailPassword,
      email,
      password
    );
  } catch (error) {
    handleError(error);
  }
};

export const login = async (provider, email, password) => {
  switch (provider) {
    case LoginProvider.Google:
      return authWithGoogle();
    case LoginProvider.Facebook:
      return authWithFacebook();
    case LoginProvider.Apple:
      return authWithApple();
    case LoginProvider.EmailPassword:
      return authWithEmailPassword(email, password);
  }
};

const authWithEmailPassword = async (email, password) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    await handleUser(userCredential, LoginProvider.EmailPassword);
  } catch (error) {
    handleError(error);
  }
};

const authWithSocialProvider = async (provider) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithPopup(auth, provider);

    // The signed-in user info.
    await handleUser(userCredential, provider);
  } catch (error) {
    handleError(error);
  }
};

const authWithGoogle = async () => {
  const provider = new GoogleAuthProvider();

  await authWithSocialProvider(provider);
};

const authWithFacebook = async () => {
  const provider = new FacebookAuthProvider();
  provider.setCustomParameters({
    display: "popup",
  });

  await authWithSocialProvider(provider);
};

const authWithApple = async () => {
  const provider = new OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");

  await authWithSocialProvider(provider);
};

export const signOut = async () => {
  try {
    const auth = getAuth();
    await signOut(auth);
    // Sign-out successful.
  } catch (error) {
    // An error happened.
  }
};
